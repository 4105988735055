.text-color { color: $color-text-base; } // needed
.text-color-header { color: $color-text-punch; }
.text-color-black { color: $color-black; } // needed

.text-color-nav,
.text-color-nav-lang,
.text-color-nav-logo {
    color: $color-black;
} // needed
.text-color-nav-lang-hover { color: $color-text-contrast; }
.text-color-nav-active { color: $color-primary; } // needed
.text-color-nav-hover { color: $color-primary; } // needed
.text-color-nav-phone { color: $color-text-contrast; } // needed
.text-color-nav-phone-hover { color: $color-text-punch; } // needed
.text-color-nav-toggle { color: $color-white; } // needed

.text-color-white { color: $color-white; } // needed
.text-color-contrast { color: $color-text-contrast; } // needed
.text-color-grey { color: $color-grey-light; }
.text-color-primary { color: $color-primary; }
.text-color-footer { color: $color-grey-light;}

.bg-color-body { background-color: $bg-color-body; }
.bg-color-gold { background-color: $bg-color-primary; }
.bg-color-black { background-color: $bg-color-black; }
.bg-color-nav { background-color: $bg-color-nav; }
.bg-color-nav-hover { background-color: $bg-color-nav; }
.bg-color-nav-active { background-color: $bg-color-nav; }
.bg-color-nav-toggle { background-color: $color-white; }
.bg-color-nav-toggle-backdrop { background-color: #d8d8d8; }
.bg-color-footer { background-color: $bg-color-footer; }
.bg-color-transparent { background-color: $bg-color-transparent; }
