$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-primary;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

.clear-both {
    clear: both;
}
#blocTitre_soumission {
    width: 100%;
    display: flex;
    padding-top: 25px;
    flex-direction: column;
    h3 {
        padding-bottom: 10px;
    }
    a.lienPhoto {
        font-weight: normal;
        font-size: $font-size-20;
        color: #000;
        line-height: 1.4;
        font-family: "Roboto";
        &:hover {
            color: #e4652a;
        }
    }
}
.bloc-checkbox.livraison-emporter {
    padding-top: 30px;
    padding-left: 15%;
    .container {
        margin-bottom: 15px;
        input[type="checkbox"] {
            height: 20px;
            width: 20px;
            position: absolute;
            left: 10%;
        }
    }
}
.section {
    background: #e6e6e6;
    padding: 35px 2% 35px 5%;
    label.titre {
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-34;
        color: #11408e;
        text-transform: uppercase;
        padding-left: 20px;
    } 
    input#puisard, input#regard, input#regard_rectangulaire, input#grille_de_securite, input#regard_puisard {
        height: 20px;
        width: 20px;
    }
    .info-form {
        display: flex;
        align-items: center;
        clear: both;
        .left-side {
            width: 62%;
            .img-responsive {
                padding: 50px 0;
            }
            input {
                height: 35px;
            }
            label {
                width: 100%;
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-20;
                color: #000000;
                text-align: right;
                padding-right: 25px;
            } 
            label.control-label.last {
                font-weight: bold !important;
            }
        }
        h4 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-26;
            color: #e4652a;
            padding-bottom: 5px;
            margin-left: -22px;
            padding-right: 10px;
        }
        .right-side {
            width: 35%;
        }
    }
    .info-form.grille-securite {
        display: flex;
        align-items: initial;
        clear: both;
        padding-top: 5%;
        h3 {
            padding-bottom: 30px;
        }
    }
}

#right {
    clear: both;
    overflow: hidden;
    position: relative;
    left: 82%;
    padding-top: 50px;
    label.image {
        font-family: $font-family-2;
        font-weight: normal;
        font-size: $font-size-24;
        border-bottom: 1px solid #11408e;
    }
    .btn-submit {
        width: 15%;
    }
}

form {
    h3 {
        font-weight: normal;
        font-size: $font-size-26; 
        color: #11408e;
        text-transform: initial;
    }
    input.form-control, textarea.form-control {
        height: $input_height;
        color: $input_text_color;
        font-size: $input_text_size;
        background-color: $input_back_color;
        border: 1px solid #000;
        border-radius: $input_border_radius;
    }
    input::placeholder, textarea::placeholder {
        font-size: $input_placeholder_size;
        color: $input_placeholder_color;
    }
    label {
        color: #11408e;
        font-size: $font-size-24;
        font-family: $font-family-1;
        font-weight: normal;
    }
    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .radios, .checkboxes {
        width: 100%;
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            &:checked+label:before {
                background-color: $input_checked_color;
            }
        }
        input[type="radio"] {
            &+label:before {
                border-radius: 100%;
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            color: $input_label_color;
            position: relative;
            padding: 6px 5px 6px 35px;
            font-size: $input_text_size;
            transition: all .3s ease;
            &:before {
                content: "";
                display: inline-block;
                width: $input_text_size;
                height: $input_text_size;
                position: absolute;
                left: 0px;
                background: none;
                border: 2px solid $label_checkbox_border_color;
            }
        }
    }
    .radios {
        justify-content: space-between;
    }
    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 15px 0 0 0;
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
