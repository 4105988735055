.slideshow-wrap {
    position: relative;
    background-color: black;
    //#layerslider { display: block; }
    //#layerslider-mobile { display: none; }
    //@media screen and (max-width: $size-slider-breakpoint) {
    //    #layerslider { display: none; }
    //    #layerslider-mobile { display: block; }
    //}

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 27%;
        left: 3%;
        transform: translateX(-125%);
        z-index: $z-index-slide-caption;
        border-right: 1px solid #fff;
        padding-right: 50px;
        h3 {
            text-transform: initial;
            font-size: $font-size-64;
            color: #fff;
            font-weight: normal;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-40;
            color: #fff;
            padding-bottom: 15px;
        }
        h4.click {
            font-size: $font-size-30;
            text-align: right;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #fff;
            padding: 10px 0;
            line-height: 1.5;
        }
        &.show {
            transform: translateX(0);
            transition: 1s;
            h3, h4, p {
                transform: translateX(0);
                transition: 1s;
            }
        }
        &:hover {
            h3, h4, p {
                color: #e59326;
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}

