.btn {
    background-color: #114293;
    color: $color-white;
    border-radius: 0;
    margin-left: 38px;
    padding: 15px;
    font-size: $font-size-20;
    width: 100%;
    transition: all 0.2s ease-in;
    span.btn-em {
        font-size: $font-size-20;
        text-transform: uppercase;
        font-weight: 600;
    }
    &:hover {
        transition: all 0.2s ease-in;
    }
}
