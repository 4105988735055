// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

p {
    font-family: $font-family-2;
}
/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/***************************************
            *** POP UP ***
****************************************/

.md-content.popup-box {
    h3 {
        font-family: $font-family-1;
        color: #11408e;
        text-transform: initial;
    }
    p { 
        font-family: $font-family-2;
        color: #29282e;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#bloc-bleu {
    background: url("../images/accueil_section01_bg.png") no-repeat;
    padding: 25px 0 145px 27%;
    margin-bottom: -82px;

    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #fff;
    }
}

#blocNoir-blocBurst {
    background: url("../images/accueil_section02_bg.jpg") no-repeat;
    min-height: 646px;
    margin-top: -75px;
    margin-bottom: -75px;

    .blocNoir {
        padding-left: 27%;
        position: relative;
        bottom: 50px;
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #fff;
            padding-bottom: 25px;
            padding-left: 30px;
        }
        .blocListe {
            display: flex;
            ul {
                padding-left: 0;
                li {
                    font-weight: normal;
                    font-size: $font-size-26;
                    color: #fff;
                    font-family: $font-family-1;
                    text-transform: initial;
                    background: url(../images/_point_blanc.png) no-repeat left;
                    list-style: none;
                    margin-bottom: 25px;
                    padding-left: 30px;
                    line-height: 1.25;
                }
            }
            .left-side {
                width: 50%;
            }
        }
    }
    .blocBurst {
        display: flex;
        padding: 25px 0 0 2%;
        .bloc {
            width: 20%;
            border-right: 2px solid #939395;
            h3 {
                font-size: $font-size-64;
                color: #29282e;
                text-transform: initial;
                font-weight: normal;
                line-height: 0.8;
                padding-bottom: 20px;
                span {
                    font-size: $font-size-40;
                    color: #29282e;
                }
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #29282e;
                line-height: 1.5;
            }
        }
        .section-burst {
            display: flex;
            width: 80%;
        
            .bloc-burst.first {
                width: 40%;
                padding: 1% 20px;
            }
            .bloc-burst {
                width: 30%;
                border-right: 2px solid #939395;
                padding: 35px;
                &:last-child {
                    border-right: none;
                }
                .burst {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding-top: 10%;
                    h3 {
                        font-size: $font-size-50;
                        color: #11408e;
                        text-transform: initial;
                        font-weight: normal;
                    }
                    h4 {
                        font-size: $font-size-34;
                        color: #11408e;
                    }
                }
                :hover {
                    h3, h4 {
                        color: #e59326;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* REHAUSSEMENT
*******************************************************************************/

#blocTitre {
    position: absolute;
    right: 2%;
    top: 37%;
    h3 {
        font-size: $font-size-30;
        color: #fff;
        font-weight: normal;
    }
}

#blocTexte-images {
    background: #29282e;
    padding-top: 60px;
    .blocTexte {
        display: flex;
        justify-content: space-around;
        .left-side {
            border-right: 1px solid #fff;
            padding-right: 5%;
            width: 40%;
            p {
                font-size: $font-size-20;
                color: #fff;
                font-weight: normal;
                text-align: right;
            }
        }
        .right-side {
            width: 65%;
            padding-left: 5%;
            h3 {
                font-size: $font-size-60;
                color: #fff;
                font-weight: normal;
                text-transform: initial;
            }
            h4 {
                font-size: $font-size-32;
                color: #fff;
                font-weight: normal;
            }
        }
    }
    .blocImages {
        display: flex;
        padding: 50px 1%;
        flex-wrap: wrap;
        .images {
            width: 33.3333%;
            padding: 15px;
            .img-responsive {
                margin: auto;
            }
            h4 {
                font-size: $font-size-26;
                color: #fff;
                font-weight: normal;
                text-align: center;
                padding-top: 10px;
            }
        }
    }
    .blocCaracteristiques {
        padding: 50px 10%;
        h3 {
            font-size: $font-size-60;
            color: #fff;
            font-weight: normal;
            text-transform: initial;
            padding-bottom: 25px;
        }
        ul {
            li {
                font-size: $font-size-24;
                color: #fff;
                font-weight: normal;
                line-height: 1.5;
                padding-bottom: 15px;
                text-transform: initial;
            }
        }
    }
}

#blocBurst {
        display: flex;
        padding: 50px 0 25px 2%;
        .bloc {
            width: 25%;
            border-right: 2px solid #939395;
            h3 {
                font-size: $font-size-64;
                color: #29282e;
                text-transform: initial;
                font-weight: normal;
                line-height: 0.8;
                padding-bottom: 20px;
                span {
                    font-size: $font-size-40;
                    color: #29282e;
                }
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #29282e;
                line-height: 1.5;
            }
        }
        .section-burst {
            display: flex;
            width: 80%;
        
            .bloc-burst.first {
                width: 35%;
                padding: 1% 20px;
            }
            .bloc-burst {
                width: 30%;
                border-right: 2px solid #939395;
                padding: 25px;
                &:last-child {
                    border-right: none;
                }
                .burst {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    padding-top: 10%;
                    h3 {
                        font-size: $font-size-50;
                        color: #11408e;
                        text-transform: initial;
                        font-weight: normal;
                    }
                    h4 {
                        font-size: $font-size-34;
                        color: #11408e;
                    }
                }
                :hover {
                    h3, h4 {
                        color: #e59326;
                    }
                }
            }
        }
    }

/*******************************************************************************
* TERRITOIRES DESSERVIS
*******************************************************************************/

#bloc-bleu_territoire {
    background: url("../images/territoires_section01_bg.png") no-repeat;
    padding-left: 5%;
    min-height: 260px;
    z-index: 1;
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #fff;
        line-height: 1.4;
        border-right: 1px solid #fff;
        width: 18%;
        text-align: right;
        padding-right: 3%;
    }
    .blocNoir_territoire {
        position: absolute;
        right: 5%;
        bottom: 31%;
        h3 {
            font-weight: normal;
            font-size: $font-size-60;
            color: #fff;
            text-transform: initial;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #fff;
        }
    }
}

#blocTerritoire {
    display: flex;
    justify-content: space-around;
    padding: 185px 5% 145px 5%;
    background: url("../images/territoires_section02_bg.jpg") no-repeat;
    margin-top: -80px;
    position: relative;
    z-index: -1;
    .bloc {
        h3 {
            font-weight: normal;
            font-size: $font-size-34;
            color: #11408e;
            padding-bottom: 20px;
        }
        .blocListe {
            display: flex;
            .liste {
                width: 45%;
                ul {
                    li {
                        font-weight: 500;
                        font-size: $font-size-20;
                        color: #000;
                        text-transform: initial;
                        line-height: 1.5;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
    .left-side {
        width: 45%;
        .bloc.first {
            padding-bottom: 55px;
        }
    }
}

#courbe-noir_territoire {
    margin-top: -125px;
}

/*******************************************************************************
* SOUMISSION
*******************************************************************************/

#bloc-noir_soumission {
    background: #29282e;
    padding: 50px 1% 50px 20%;
    .blocTexte {
        border-left: 1px solid #fff;
        width: 50%;
        margin: auto;
        padding-left: 35px;
        h3 {
            font-weight: normal;
            font-size: $font-size-60;
            color: #fff;
            text-transform: initial;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #fff;
        }
    } 
}

#blocFormulaire {
    padding-top: 50px;
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#bloc-bleu_coordonnees {
    background: url("../images/coordonnees_section01_bg.png") no-repeat;
    padding-left: 5%;
    min-height: 178px;
    z-index: 1;
    
    .blocNoir_coordonnees {
        position: absolute;
        left: 30%;
        bottom: 40%;
        h3 {
            font-weight: normal;
            font-size: $font-size-60;
            color: #fff;
            text-transform: initial;
            padding-bottom: 5px;
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-32;
            color: #fff;
        }
    }
}

#blocCoordonnees {
    display: flex;
    margin-top: -78px;
    position: relative;
    z-index: -1;
    .left-side {
        width: 30%;
        background: url("../images/coordonnees_section02_bg.jpg") no-repeat;
        padding: 100px 2% 0 2%;
        .adresse {
            display: flex;
            .icon {
                padding-right: 20px;
            }
        }
        .contact {
            padding: 75px 0;
            h3 {
                padding-bottom: 20px;
            }
        }
        .blocTitre {
            display: flex;
            align-items: center;
            .icon {
                padding-right: 20px;
            }
        }
        .heures {
            h3 {
                padding-bottom: 20px;
            }
        }
        .blocHeures {
            display: flex;
            .jour {
                width: 35%;
            }
            p {
                line-height: 1.3;
            }
        }
        h3 {
            font-weight: normal;
            font-size: $font-size-34;
            color: #11408e;
        }
        p {
            font-weight: normal;
            font-size: $font-size-24;
            color: #29282e;
            line-height: 1;
            padding-left: 55px;
        }
    }
    .right-side {
        width: 70%;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1880px) {
    
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 175px;
}
#bloc-bleu_territoire p br {
    display: none;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 5%;
    bottom: 25%;
}
#blocTitre {
    right: 2%;
    top: 40%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 30%;
    bottom: 34%;
}
#bloc-noir_soumission .blocTexte {
    width: 55%;
}
}

@media screen and (max-width: 1825px) {
    
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 130px;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 26%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 30%;
    bottom: 35%;
}
}

@media screen and (max-width: 1790px) {
  
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 100px;
}  
#bloc-bleu p br {
    display: none;
}    
#bloc-bleu {
    padding: 10px 2% 120px 30%;
}
#blocNoir-blocBurst .blocNoir {
    padding-left: 30%;
}
#blocNoir-blocBurst .blocBurst .bloc-burst.first {
    padding: 1% 10px;
}
#blocNoir-blocBurst .blocBurst .bloc p br {
    display: none;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2.8rem;
}
#bloc-bleu_territoire .blocNoir_territoire h4 {
    font-size: 1.4rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 4%;
    bottom: 27%;
}
#blocTexte-images .blocTexte .right-side h3 {
    font-size: 2.8rem;
}
}

@media screen and (max-width: 1750px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 60px;
}
#bloc-bleu_territoire p {
    font-size: 0.9rem;
}
#blocTitre {
    right: 2%;
    top: 38%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 32%;
    bottom: 37%;
}
}

@media screen and (max-width: 1710px) {

nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 25px;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 2%;
    bottom: 28%;
}
#blocTerritoire .left-side {
    width: 60%;
}
#bloc-noir_soumission .blocTexte {
    width: 60%;
}
}

@media screen and (max-width: 1690px) {
    
#blocNoir-blocBurst .blocBurst .bloc-burst .burst h4, #blocBurst .bloc-burst .burst h4 {
    font-size: 1.5rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 10px;
}
#blocTitre {
    right: 2%;
    top: 38%;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2.6rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 29%;
}
#blocTexte-images .blocTexte .left-side p {
    line-height: 1.5;
}
#blocTexte-images .blocTexte .right-side h3 {
    font-size: 2.5rem;
}
}

@media screen and (max-width: 1660px) {
    
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst.first, #blocBurst .section-burst .bloc-burst.first {
    padding: 1% 10px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2.3rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h4, #blocBurst .section-burst .bloc-burst .burst h4 {
    font-size: 1.5rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 0;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 1rem;
}
.slideshow-wrap .caption {
    top: 17%;
    left: 3%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 34%;
    bottom: 38%;
}
}

@media screen and (max-width: 1580px) {

#blocNoir-blocBurst .blocBurst {
    padding: 0 0 0 2%;
}
#blocNoir-blocBurst .blocBurst .bloc {
    width: 25%;
}   
#bloc-bleu {
    padding: 0px 2% 88px 36%;
    margin-top: -10px;
}
#blocNoir-blocBurst .blocNoir {
    padding-left: 34%;
}
#blocNoir-blocBurst .blocBurst a.bloc-burst .burst .icon {
    display: none;
}
#blocNoir-blocBurst .blocBurst .bloc-burst.first, #blocBurst .bloc-burst.first {
    width: 25%;
    padding: 2% 10px;
}
#blocNoir-blocBurst .blocBurst .bloc-burst {
    padding: 35px 10px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2.1rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst {
    width: 40%;
    padding: 15px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.9rem;
}
#blocNoir-blocBurst {
    margin-top: -40px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst {
    padding-top: 20%;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2.4rem;
    padding-bottom: 10px;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 30%;
}
#bloc-bleu_territoire .blocNoir_territoire h4 {
    font-size: 1.3rem;
}
#blocTitre {
    right: 2%;
    top: 36%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 35%;
    bottom: 39%;
}
#blocCoordonnees .left-side h3 {
    font-size: 1.5rem;
}
#blocTexte-images .blocImages .images h4 {
    font-size: 1.1rem;
}
}

@media screen and (max-width: 1550px) {

nav.module-menu .menu-navbar .wrapper.right {
    padding-right: 1rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    padding-left: 0.6rem;
    padding-right: 0.6rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 35px;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 1%;
    bottom: 20%;
}
#bloc-bleu_territoire p {
    font-size: 0.9rem;
    line-height: 1.3;
}
#blocTerritoire .left-side {
    width: 70%;
}
#blocTitre {
    right: 2%;
    top: 41%;
}
.module-header--caption h3 {
    font-size: 2.5rem;
}
.module-header--caption h4 {
    font-size: 1.2rem;
}
#blocTexte-images .blocTexte .right-side h3, #blocTexte-images .blocCaracteristiques h3 {
    font-size: 2.3rem;
}
#blocTexte-images .blocCaracteristiques ul li {
    font-size: 1.1rem;
}
#blocBurst .bloc {
    width: 30%;
}
#blocBurst .section-burst .bloc-burst {
    width: 35%;
    padding: 15px;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 36%;
    bottom: 30%;
}
#blocCoordonnees .left-side .blocHeures .jour {
    width: 45%;
}
#bloc-noir_soumission .blocTexte {
    width: 65%;
}
}

@media screen and (max-width: 1549px) {

#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 31%;
}
}

@media screen and (max-width: 1535px) {

#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 36%;
    bottom: 40%;
}
}

@media screen and (max-width: 1530px) {

#bloc-bleu_territoire .blocNoir_territoire {
    right: 1%;
    bottom: 31%;
}
#blocTitre {
    right: 2%;
    top: 35%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 36%;
    bottom: 40%;
}
}

@media screen and (max-width: 1505px) {
    
#bloc-bleu {
    background-size: cover;
    margin-bottom: -95px;
}
#blocTitre {
    right: 2%;
    top: 36%;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2.2rem;
    padding-bottom: 10px;
}
#bloc-bleu_territoire .blocNoir_territoire h4 {
    font-size: 1.2rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 32%;
}
}

@media screen and (max-width: 1500px) {
    
nav.module-menu .menu-navbar {
    background: url("../images/accueil_bande_top_1500.jpg") no-repeat;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 340px;
    margin-left: -40px;
    padding-top: 5px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 75px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 1rem;
}
#blocTitre {
    right: 2%;
    top: 35%;
}
}

@media screen and (max-width: 1490px) {

#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst {
    padding: 10px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 50px;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 37%;
    bottom: 41%;
}
}

@media screen and (max-width: 1450px) {

.slideshow-wrap .caption h3 {
    font-size: 3rem;
}
.slideshow-wrap .caption p {
    font-size: 0.9rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 2%;
    bottom: 33%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 10px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a, nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > .menu-title {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 1rem;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 20%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 38%;
    bottom: 42%;
}
#blocCoordonnees .left-side h3 {
    font-size: 1.4rem;
}
#bloc-noir_soumission .blocTexte {
    width: 70%;
}
}

@media screen and (max-width: 1415px) {
    
#blocNoir-blocBurst .blocNoir .blocListe ul li {
    font-size: 1.1rem;
}
#bloc-bleu {
    padding: 0px 2% 115px 41%;
}
#blocNoir-blocBurst .blocNoir {
    padding-left: 39%;
    padding-top: 20px;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu .facebook-tel {
    padding-left: 0;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2rem;
    padding-bottom: 10px;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 4%;
    bottom: 34%;
}
#blocTerritoire .left-side {
    width: 80%;
}
#blocTitre {
    right: 2%;
    top: 34%;
}
.module-header--caption h3 {
    font-size: 2.3rem;
}
}

@media screen and (max-width: 1365px) {
    
#blocNoir-blocBurst .blocBurst .bloc-burst .burst h3 {
    font-size: 2.1rem;
}
#blocNoir-blocBurst .blocBurst .bloc-burst .burst h4 {
    font-size: 1.2rem;
}
#blocNoir-blocBurst .blocBurst .bloc {
    width: 29%;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst {
    padding-top: 30%;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 22%;
}
#blocTitre {
    right: 2%;
    top: 34%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.9rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 2%;
    bottom: 35%;
}
#blocTitre {
    right: 2%;
    top: 33%;
}
#blocTexte-images .blocTexte .right-side h3 {
    font-size: 2.1rem;
}
#blocBurst .bloc {
    width: 35%;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 40%;
    bottom: 44%;
}
#blocCoordonnees .left-side h3 {
    font-size: 1.3rem;
}
#blocCoordonnees .left-side {
    padding: 100px 2% 0 1%;
    width: 40%;
}
}

@media screen and (max-width: 1335px) {
    
#bloc-bleu {
    padding: 0px 2% 95px 41%;
    margin-bottom: -120px;
}
#bloc-bleu p {
    padding-bottom: 65px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst.first {
    width: 43%;
}
.slideshow-wrap .caption h4 {
    font-size: 1.5rem;
}
.slideshow-wrap .caption h3 {
    font-size: 2.5rem;
}
.slideshow-wrap .caption {
    top: 15%;
    left: 3%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li div {
    font-size: 0.8rem;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 1.9rem;
    padding-bottom: 10px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2.1rem;
}
#bloc-noir_soumission .blocTexte {
    width: 75%;
}
#blocTexte-images .blocCaracteristiques {
    padding: 50px 7%;
}
}

@media screen and (max-width: $size-md-max) {
    
#blocNoir-blocBurst {
    margin-top: -10px;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2rem;
    padding-bottom: 10px;
}
#bloc-bleu_territoire .blocNoir_territoire h4 {
    font-size: 1.3rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    right: 3%;
    bottom: 37%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 390px;
}
nav.module-menu .menu-toggle {
    padding-top: 50px;
}
nav.module-menu .menu-navbar {
    height: 128px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst {
    padding-top: 25%;
}
#blocNoir-blocBurst .blocNoir {
    bottom: 80px;
}
nav.module-menu .menu-navbar {
    background: url(../images/accueil_bande_top.jpg) no-repeat;
}
.slideshow-wrap .caption p {
    font-size: 0.8rem;
}
.slideshow-wrap .caption h4 {
    font-size: 1.3rem;
}
.slideshow-wrap .caption h3 {
    font-size: 2.3rem;
}
.slideshow-wrap .caption {
    top: 32%;
    left: 3%;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 22%;
    padding-top: 10px;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 42%;
    bottom: 46%;
}
#blocCoordonnees .left-side h3 {
    font-size: 1.5rem;
}
#blocCoordonnees .left-side .contact {
    padding: 60px 0;
}
.btn {
    margin-left: 16px;
}
#right {
    left: 84%;
}
#blocTexte-images .blocImages .images {
    width: 33%;
    padding: 15px;
    margin: auto;
}
}

@media screen and (max-width: 1265px) {

.slideshow-wrap .caption p {
    display: none;
}
.slideshow-wrap .caption {
    top: 45%;
}
#blocTitre {
    right: 2%;
    top: 32%;
}
}

@media screen and (max-width: 1215px) {

#bloc-bleu_coordonnees .blocNoir_coordonnees {
    left: 44%;
    bottom: 47%;
}
}

@media screen and (max-width: 1180px) {
    
#bloc-bleu {
    background: #11418d;
    padding: 0px 2% 50px 41%;
    margin-bottom: -75px;
}
#bloc-bleu_coordonnees {
    background: #11418d;
    padding: 0px;
    margin-bottom: 0;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    background: #29282e;
    width: 100%;
    position: initial;
    padding: 50px 5%;
}
#blocNoir-blocBurst .blocBurst {
    padding: 50px 0 0 2%;
}
#blocNoir-blocBurst .blocNoir p {
    padding-bottom: 10px;
}
#blocNoir-blocBurst .blocNoir .blocListe ul li {
    margin-bottom: 15px;
}
#blocNoir-blocBurst {
    min-height: 615px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h4 {
    font-size: 1.2rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst {
    padding-top: 30%;
}
#bloc-bleu_territoire .blocNoir_territoire {
    background: #29282e;
    width: 100%;
    position: initial;
    padding: 50px 5%;
}
#bloc-bleu_territoire {
    background: #114291;
    padding-left: 0;
    min-height: 260px;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 31%;
    padding-top: 10px;
    padding-left: 5%;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 30%;
    padding: 35px 35px 35px 5%;
}
#blocTitre {
    right: 2%;
    top: 31%;
}
#blocTerritoire {
    padding: 50px 5% 85px 5%;
    background: #d9d7d8;
    margin-top: 0;
    flex-direction: column-reverse;
}
#courbe-noir_territoire {
    margin-top: -50px;
}
#blocTerritoire .right-side {
    width: 45%;
    padding-bottom: 35px;  
}
#blocTerritoire .left-side {
    width: 85%;
}
#blocCoordonnees {
    margin-top: 0;
}
#blocCoordonnees .left-side {
    padding: 75px 2%;
    width: 50%;
}
#blocCoordonnees .right-side {
    width: 50%;
}
div#map-canvas {
    height: 755px !important;
}
#courbe-noir_territoire {
    display: none;
}
.section .info-form .left-side label {
    font-size: 0.9rem;
}
#right {
    left: 81%;
}
.btn {
    margin-left: 45px;
}
}

@media screen and (max-width: 1105px) {
    
#blocNoir-blocBurst .blocBurst .bloc-burst .burst h4 {
    font-size: 1.1rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2rem;
}
#blocTexte-images .blocTexte .left-side p br {
    display: none;
}
#blocTitre {
    right: 2%;
    top: 30%;
}
#blocTexte-images .blocTexte .right-side h3 {
    font-size: 2rem;
}
#blocTexte-images .blocTexte .left-side {
    padding-left: 15px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 1.9rem;
}
#bloc-noir_soumission .blocTexte {
    width: 80%;
}
}

@media screen and (max-width: 1024px) {
    
#courbe-noir {
    display: none;
}
#blocNoir-blocBurst .blocNoir {
    background: #29282e;
    padding: 50px 5%;
    position: initial;
}
#blocNoir-blocBurst {
    margin-top: 0px;
    min-height: 0;
    margin-bottom: 0;
}
#bloc-bleu {
    padding: 40px 5% 5px 5%;
    margin-bottom: 0;
}
#blocNoir-blocBurst .blocNoir .blocListe .left-side {
    width: 65%;
}
#blocNoir-blocBurst .blocBurst, #blocBurst {
    padding: 50px 2%;
    flex-direction: column;
    background: #e6e6e6;
}
#blocNoir-blocBurst .blocBurst .bloc, #blocBurst .bloc {
    width: 100%;
    border-right: none;
    padding-bottom: 25px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst, #blocBurst .section-burst .bloc-burst .burst {
    padding-top: 25px;
}
#blocNoir-blocBurst .blocBurst .section-burst, #blocBurst .section-burst {
    margin: auto;
    width: 90%;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst.first, #blocNoir-blocBurst .blocBurst .section-burst .bloc-burst {
    width: 50%;
}
#blocTerritoire .left-side {
    width: 90%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 350px;
}
#blocBurst .bloc p br {
    display: none;
}
#blocTitre {
    right: 2%;
    top: 32%;
}
#bloc-noir_soumission .blocTexte {
    width: 85%;
}
#blocTexte-images .blocCaracteristiques {
    padding: 50px 5%;
}
}

@media screen and (max-width: $size-sm-max) {
    
#blocNoir-blocBurst .blocBurst .section-burst {
    width: 100%;
}
.slideshow-wrap .caption h3 {
    font-size: 2rem;
}
.slideshow-wrap .caption h4 {
    font-size: 1.2rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 315px;
}
#blocTitre {
    right: 2%;
    top: 23%;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 40%;
}
#blocTerritoire .right-side {
    width: 60%;
    margin: auto;
    padding-bottom: 35px;
}
#blocTerritoire .left-side {
    width: 100%;
}
#blocTitre {
    right: 2%;
    top: 21%;
}
.module-header--caption h3 {
    font-size: 2rem;
}
#blocTexte-images .blocTexte {
    flex-direction: column-reverse;
}
#blocTexte-images .blocTexte .right-side {
    width: 100%;
    padding-left: 2%;
}
#blocTexte-images .blocTexte .left-side {
    padding-left: 0;
    border-right: none;
    padding: 50px 2% 0 2%;
    width: 100%;
}
#blocTexte-images .blocImages .images {
    width: 50%;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 1.8rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst, #blocBurst .section-burst .bloc-burst .burst {
    padding-top: 0;
}
#blocCoordonnees {
    flex-direction: column;
    position: initial;
}
#blocCoordonnees .left-side {
    padding: 75px 2%;
    width: 100%;
    background: #dedcdd;
}
#blocCoordonnees .right-side {
    width: 100%;
}
div#map-canvas {
    height: 500px !important;
}
#blocCoordonnees .left-side .blocHeures .jour {
    width: 25%;
}
#right {
    left: 70%;
}
.section .info-form {
    flex-direction: column-reverse;
}
.section .info-form .left-side {
    width: 85%;
}
.section .info-form .left-side .img-responsive {
    margin: auto;
}
.section .info-form .right-side {
    width: 45%;
    padding-top: 30px;
}
.section .info-form .left-side label {
    font-size: 1rem;
}
#bloc-noir_soumission .blocTexte {
    width: 100%;
}
#bloc-noir_soumission .blocTexte h3 {
    font-size: 2.8rem;
}
#blocTitre_soumission {
    padding-top: 10px;
}
.section .info-form.grille-securite .right-side {
    width: 45%;
    margin: auto;
    padding-top: 0;
    padding-bottom: 40px;
}
}

@media screen and (max-width: $size-xs-max) {
   
#blocNoir-blocBurst .blocBurst .section-burst, #blocBurst .section-burst{
    flex-direction: column;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst.first, #blocNoir-blocBurst .blocBurst .section-burst .bloc-burst, #blocBurst .section-burst .bloc-burst.first, #blocBurst .section-burst .bloc-burst {
    width: 75%;
    margin: auto;
    border-right: none;
    border-bottom: 2px solid #939395;
    padding-bottom: 28px;
    padding-top: 28px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst:last-child, #blocBurst .section-burst .bloc-burst:last-child {
    border-bottom: none;
}
#bloc-bleu {
    padding: 45px 5%;
    margin-bottom: -70px;
}
#blocNoir-blocBurst .blocNoir p {
    padding-left: 0;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2.5rem;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h4, #blocBurst .section-burst .bloc-burst .burst h4 {
    font-size: 1.5rem;
    text-align: center;
}
#blocNoir-blocBurst .blocBurst, #blocBurst {
    padding: 50px 5%;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 335px;
}
nav.module-menu .menu-toggle {
    padding-top: 0;
}
nav.module-menu .menu-toggle .word {
    color: #000;
}
nav.module-menu .menu-toggle .bar-top, nav.module-menu .menu-toggle .bar-bottom {
    background-color: #000;
}
.slideshow-wrap .caption p {
    display: block;
    font-size: 1rem;
}
.slideshow-wrap .caption h3 {
    font-size: 2.8rem;
}
.slideshow-wrap .caption h4 {
    font-size: 1.8rem;
}
.slideshow-wrap .caption {
    top: 40%;
    border: none;
}
#blocTitre {
    position: initial;
    background: #114291;
    padding: 25px;
    text-align: right;
}
#bloc-bleu_territoire p {
    line-height: 1.4;
    width: 100%;
    border-right: none;
    border-top: 1px solid #fff;
    margin: auto;
    text-align: left;
    font-size: 1rem;
}
#bloc-bleu_territoire .blocNoir_territoire {
    padding: 50px 3%;
}
#bloc-bleu_territoire .blocNoir_territoire h3 {
    font-size: 2.4rem;
    padding-bottom: 15px;
}
#blocTerritoire .right-side {
    width: 65%;
}
.module-header--caption {
    top: 85%;
    left: 5%;
}
.module-header--caption h3 {
    font-size: 3rem;
}
.module-header--caption h4 {
    font-size: 1.5rem;
}
#bloc-bleu_coordonnees .blocNoir_coordonnees {
    padding: 55px 5%;
}
#blocCoordonnees .left-side {
    padding: 50px 2%;
}
#blocCoordonnees .left-side .contact {
    padding: 50px 0;
}
form h3 {
    padding-left: 3%;
}
.bloc .info-form .right-side {
    width: 55%;
}
#right {
    position: initial;
}
.btn {
    margin-left: -15px;
}
#right .btn-submit {
    width: 48%;
}
.bloc .info-form .left-side label {
    margin: 0;
}
#blocTexte-images .blocImages .images {
    width: 100%;
}
#blocTexte-images .blocImages .images h4 {
    font-size: 1.2rem;
}
#blocTexte-images .blocCaracteristiques {
    padding: 5px 5% 30px 5%;
}
.bloc-checkbox.livraison-emporter {
    padding-top: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    height: 75px;
}
.bloc-checkbox.livraison-emporter .container input[type="checkbox"] {
    left: 0%;
    margin-top: -1px;
}
.bloc-checkbox.livraison-emporter .container {
    margin-left: initial;
}
.bloc-checkbox.livraison-emporter .container {
    margin-bottom: 25px;
}
#blocFormulaire .section .form-group label.titre.regard_puisard {
    padding-left: 35px;
    margin-top: -22px;
}
}

@media screen and (max-width: 360px) {

.slideshow-wrap .caption {
    top: 35%;
}
.slideshow-wrap .caption h3 {
    font-size: 2.5rem;
}
.slideshow-wrap .caption h4 {
    font-size: 1.5rem;
}
#blocTerritoire .bloc .blocListe .liste ul li {
    font-size: 0.9rem;
}
nav.module-menu .menu-navbar .wrapper.left .logo {
    max-width: 305px;
}
#blocNoir-blocBurst .blocBurst .section-burst .bloc-burst .burst h3, #blocBurst .section-burst .bloc-burst .burst h3 {
    font-size: 2.1rem;
    text-align: center;
}
#blocTexte-images .blocImages .images {
    width: 100%;
    padding: 25px;
}
#blocTexte-images .blocCaracteristiques h3 {
    font-size: 2.2rem;
}
}